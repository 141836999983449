import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="404: Not Found" />
        <div class="not-found">
        <div class="not-found-type"><h1>Page not found</h1>
        <p>Nothing to see here.</p>
        </div></div>
      </Layout>
    )
  }
}

export default NotFoundPage
